import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import ReactCountryFlag from 'react-country-flag';

import raceCalendarStyles from './race-calendar.module.css';

export const query = graphql`
  {
    allPrismicRaceEvent(
      sort: { fields: data___start_date, order: ASC }
      filter: { data: { confirmed: { eq: true } } }
    ) {
      edges {
        node {
          id
          data {
            car
            confirmed
            end_date
            name
            race_format
            series_or_championship_name
            start_date
            team
            race_report {
              id
              uid
            }
            track {
              id
              document {
                ... on PrismicTrack {
                  data {
                    country
                    name
                    track_map {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

function RaceDate({ startDate, endDate }) {
  const endsOnDifferentMonth = () => startDate.getUTCMonth() !== endDate.getUTCMonth();

  if (!endDate) {
    return (
      <span className={raceCalendarStyles.startDate}>
        {startDate.getDate()} {startDate.toLocaleDateString('default', { month: 'long' })}
      </span>
    );
  }

  if (endsOnDifferentMonth()) {
    return (
      <div>
        <span className={raceCalendarStyles.startDate}>
          {startDate.getDate()} {startDate.toLocaleString('default', { month: 'long' })}
        </span>{' '}
        -
        <span className={raceCalendarStyles.endDate}>
          {endDate.getDate()} {endDate.toLocaleString('default', { month: 'long' })}
        </span>
      </div>
    );
  }

  return (
    <div>
      <span className={raceCalendarStyles.startDate}>{startDate.getDate()}</span> -
      <span className={raceCalendarStyles.endDate}>
        {endDate.getDate()} {endDate.toLocaleString('default', { month: 'long' })}
      </span>
    </div>
  );
}

export default function RaceCalendar({ year = new Date().getFullYear() }) {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const raceEvents = data.allPrismicRaceEvent.edges
          .map(entry => {
            entry.node.data.id = entry.node.id;

            return entry.node.data;
          })
          .filter(
            raceEvent =>
              new Date(raceEvent.start_date) >= new Date(`${year}-01-01`) &&
              new Date(raceEvent.start_date) < new Date(`${parseInt(year, 10) + 1}-01-01`)
          );

        for (let raceEvent of raceEvents) {
          if (raceEvent.track.document) {
            raceEvent.track = raceEvent.track.document.data;
          }

          raceEvent.start_date = new Date(raceEvent.start_date);

          if (raceEvent.end_date) {
            raceEvent.end_date = new Date(raceEvent.end_date);
          }
        }

        // Check if any of the race events has an associated race report.
        // We need to adjust column widths if any race event has a race report to show.
        const hasRaceReport = raceEvents.filter(raceEvent => raceEvent.race_report?.uid).length > 0;

        return (
          <div
            className={`${raceCalendarStyles.calendar} ${
              hasRaceReport ? raceCalendarStyles.calendarWithRaceReports : ''
            }`}
          >
            {raceEvents.map(raceEvent => (
              <div
                key={raceEvent.id}
                className={`${raceCalendarStyles.race} ${!raceEvent.confirmed ? 'unconfirmed' : ''}`}
              >
                <div className={raceCalendarStyles.country}>
                  <ReactCountryFlag
                    className={raceCalendarStyles.flag}
                    countryCode={raceEvent.track.country}
                    svg
                    style={{
                      width: '2em',
                      height: '2em',
                    }}
                    title={raceEvent.track.country}
                  />
                </div>

                <span className={raceCalendarStyles.trackName}>{raceEvent.track.name}</span>

                <div className={raceCalendarStyles.date}>
                  <RaceDate startDate={raceEvent.start_date} endDate={raceEvent.end_date}></RaceDate>

                  <span className={raceCalendarStyles.provisional}>{!raceEvent.confirmed ? 'Provisional' : ''}</span>
                </div>

                {raceEvent.track.track_map.url && (
                  <img
                    className={raceCalendarStyles.trackMap}
                    alt={'Map of ' + raceEvent.track.name}
                    src={raceEvent.track.track_map.url}
                  ></img>
                )}

                <div className={raceCalendarStyles.car}>{raceEvent.car}</div>

                <div className={raceCalendarStyles.duration}>{raceEvent.race_format}</div>

                <div className={raceCalendarStyles.raceSeries}>{raceEvent.series_or_championship_name}</div>
                <div className={raceCalendarStyles.team}>{raceEvent.team}</div>
                <div className={raceCalendarStyles.readRaceReport}>
                  {raceEvent?.race_report?.uid ? (
                    <a
                      className={`${raceCalendarStyles.readRaceReportButton} button`}
                      href={`/race-report/${raceEvent.race_report.uid}`}
                    >
                      Read race report
                    </a>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ))}
          </div>
        );
      }}
    />
  );
}

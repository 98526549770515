import React, { useState } from 'react';

import Pagination from 'react-bootstrap/Pagination';

import SiteMetadata from '../components/site-metadata/site-metadata';
import Header from '../components/header/header';
import NextRace from '../components/next-race/next-race';
import RaceCalendar from '../components/race-calendar/race-calendar';
import Footer from '../components/footer/footer';
import SponsorsFooter from '../components/sponsors-footer/sponsors-footer';

import calendarStyles from './calendar.module.css';

export default function Calendar() {
  const years = [2021, 2022, 2023].sort();
  const [active, setActive] = useState(years[years.length - 1]);

  const yearButtonClicked = selectedYear => {
    setActive(selectedYear);
  };

  return (
    <div>
      <SiteMetadata
        title="Race Calendar"
        description="Check out the full calendar of races for 2023 and find the race reports from past races across the UK and Spain."
      />

      <Header activePageName="calendar" />
      <NextRace />

      <h1 className="text-center mt-10 mb-10">Race Calendar - {active}</h1>

      <div className={calendarStyles.yearSelectContainer}>
        <Pagination size="lg">
          {years.map(year => (
            <Pagination.Item key={year} active={year === active} onClick={() => yearButtonClicked(year)}>
              {year}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>

      {years.reverse().map(year => (
        <div
          className={`${calendarStyles.container} ${year === active ? calendarStyles.containerActive : ''}`}
          key={`calendar-${year}`}
        >
          <div className={calendarStyles.calendar}>
            <RaceCalendar year={year} />
          </div>
        </div>
      ))}

      <SponsorsFooter />
      <Footer />
    </div>
  );
}
